import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

export const Countdown = () => {
  // Set initial time to 15 seconds
  const [seconds, setSeconds] = useState(8);

  // useEffect to handle the countdown logic
  useEffect(() => {
    if (seconds > 0) {
      const timerId = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);

      // Cleanup the interval on component unmount
      return () => clearInterval(timerId);
    }
  }, [seconds]);

  return (
    <Typography variant="body1">
      Die Daten werden in {seconds} Sekunden geladen ...
    </Typography>
  );
};
