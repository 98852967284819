import React, { useReducer, createContext, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

// Initial state
const initialState = {
    cart: {},
    wish: {},
};

const Context = createContext();

function cartReducer(state, action) {
    let item;
    switch (action.type) {
        case "SET_CART":
            return {
                ...state,
                cart: action.payload,
            };
        case "SET_WISH":
            return {
                ...state,
                wish: action.payload,
            };
        case "ADD_TO_CART":
            item = state.cart?.[action.payload.isbn];
            return {
                ...state,
                cart: {
                    ...state.cart,
                    [action.payload.isbn]: item
                        ? { ...item, qty: item.qty + 1 }
                        : { ...action.payload, qty: 1 },
                },
            };
        case "REMOVE_FROM_CART":
            const newCart = { ...state.cart };
            delete newCart[action.payload.isbn];
            return {
                ...state,
                cart: newCart,
            };
        case "ADD_TO_WISH":
            item = state.wish?.[action.payload.isbn];
            return {
                ...state,
                wish: {
                    ...state.wish,
                    [action.payload.isbn]: item
                        ? { ...item, qty: item.qty + 1 }
                        : { ...action.payload, qty: 1 },
                },
            };
        case "REMOVE_FROM_WISH":
            const newWish = { ...state.wish };
            delete newWish[action.payload.isbn];
            return {
                ...state,
                wish: newWish,
            };
        default:
            return state;
    }
}

// Async initializer function
const initializeState = async (userId) => {
    let state = { cart: {}, wish: {} };
    if (userId) {
        try {
            const userDocRef = doc(db, "users", userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const data = userDoc.data();
                state.cart = data.cart || {};
                state.wish = data.wish || {};
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }
    return state;
};

const Provider = ({ children }) => {
    const { user } = useAuth();
    const [state, dispatch] = useReducer(cartReducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            if (user?.uid) {
                const fetchedState = await initializeState(user.uid);
                if (Object.keys(fetchedState.cart).length > 0 || Object.keys(fetchedState.wish).length > 0) {
                    dispatch({ type: "SET_CART", payload: fetchedState.cart });
                    dispatch({ type: "SET_WISH", payload: fetchedState.wish });
                }
            }
        };

        initialize();
    }, [user]);

    // useEffect(() => {
    //     const saveStateToFirestore = async () => {
    //         if (user?.uid) {
    //             const userDocRef = doc(db, "users", user.uid);
    //             const currentData = await getDoc(userDocRef);
    //             let existingData = currentData.exists() ? currentData.data() : { cart: {}, wish: {} };

    //             const mergedData = {
    //                 cart: { ...existingData.cart, ...state.cart },
    //                 wish: { ...existingData.wish, ...state.wish },
    //             };

    //             await setDoc(userDocRef, mergedData, { merge: true });
    //         }
    //     };
    //     saveStateToFirestore();
    // }, [state.cart, state.wish, user]);

    useEffect(() => {
        const saveStateToFirestore = async () => {
            if (user?.uid) {
                const userDocRef = doc(db, "users", user.uid);
                const currentData = await getDoc(userDocRef);
                let existingData = currentData.exists() ? currentData.data() : { cart: {}, wish: {} };

                // Merge the cart and wish lists, prioritizing local state but respecting deletions
                const mergedCart = { ...existingData.cart, ...state.cart };
                const mergedWish = { ...existingData.wish, ...state.wish };

                // Remove items from Firestore data that have been deleted locally
                Object.keys(existingData.cart).forEach(isbn => {
                    if (!state.cart[isbn]) {
                        delete mergedCart[isbn];
                    }
                });

                Object.keys(existingData.wish).forEach(isbn => {
                    if (!state.wish[isbn]) {
                        delete mergedWish[isbn];
                    }
                });
                console.log("mergedCart", mergedCart);
                console.log("mergedWish", mergedWish);


                // Update Firestore with the merged data
                await setDoc(userDocRef, { cart: mergedCart, wish: mergedWish }, { merge: false });
            }
        };

        saveStateToFirestore();
    }, [state.cart, state.wish, user]);

    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export { Context, Provider };
